export type NavbarRoute = {
    label: string;
    route: string;
    restrictTo?: string;
    roles?: string[];
}

export const NAVBAR_ROUTES: NavbarRoute[] = [
    {
        label: 'Products',
        route: 'product-capture',
        restrictTo: 'VIEW_PRODUCT_DATA'
    },
    {
        label: 'Sales',
        route: 'sales',
        restrictTo: 'VIEW_SALES'
    },
    {
        label: 'Lifecycle Management',
        route: 'lifecycle-events',
        restrictTo: 'VIEW_LIFECYCLE_MANAGEMENT'
    },
    {
        label: 'Fees Management',
        route: 'fees-management',
        restrictTo: 'VIEW_FEES_MANAGEMENT'
    },
    {
        label: 'Static Data',
        route: 'static-data',
        restrictTo: 'VIEW_STATIC_DATA'
    },
    {
        label: 'Connectivity',
        route: 'connectivity',
        roles: ['CS_REQUESTS_VIEWER', 'CS_CONNECTIONS_VIEWER', 'CS_SCHEDULED_MESSAGES_VIEWER']
    },
    {
        label: 'Release Management',
        route: 'release-management',
        restrictTo: 'VIEW_RELEASE_MANAGEMENT_SECTION'
    },
    {
        label: 'Enrichment',
        route: 'enrichment',
        restrictTo: 'VIEW_ENRICHMENT_CONFIG'
    },
    {
        label: 'Ingestion',
        route: 'ingestion',
        restrictTo: 'VIEW_INGESTION_CONFIG'
    },
    {
        label: 'Rules',
        route: 'rules',
        restrictTo: 'VIEW_RULES'
    },
    {
        label: 'Validation',
        route: 'validation',
        restrictTo: 'VIEW_RULES'
    },
    {
        label: 'Regression',
        route: 'regression',
        restrictTo: 'VIEW_REGRESSION_SECTION'
    },
    {
        label: 'Workflows',
        route: 'workflows',
        restrictTo: 'VIEW_ADMIN_SECTION'
    },
    {
        label: 'Admin',
        route: 'auth-o3/admin',
        restrictTo: 'VIEW_ADMIN_SECTION'
    },
    {
        label: 'Settings',
        route: 'settings',
        restrictTo: 'VIEW_ADMIN_SECTION'
    }
];

