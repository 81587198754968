import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { RestrictIfService } from './restrict-if.service';

@Directive({
  selector: '[restrictTo]',
})
export class RestrictToDirective {

  constructor(private service: RestrictIfService, 
    private vcr: ViewContainerRef,
    private tempRef: TemplateRef<any>) { }


  @Input()
  set restrictTo(permission: string | string[]) {
    if (!permission?.length) { 
      this.vcr.createEmbeddedView(this.tempRef);
      return;
    }

    this.vcr.clear();

    if (typeof permission === 'string') { 
      permission = [permission];
    }
    
    this.service.restrictTo(...permission)
      .pipe(first())
      .subscribe((hasPermission: boolean) => { 
        if (hasPermission) {
          this.vcr.createEmbeddedView(this.tempRef);
        }
      });
    }

  ngOnDestroy(): void {
    this.vcr.clear();
  }

}
