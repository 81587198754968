import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomErrorsTextPipe } from '../product-capture/create-product/legal-form/custom-errors-text.pipe';
import { BooleanToYesNoPipe } from './pipes/boolean-to-yes-no.pipe';
import { RoundAndFormatPipe } from './pipes/round-and-format.pipe';
import { ValueOrDashPipe } from './pipes/value-or-dash.pipe';
import { MultilineLabelPipe } from './pipes/multiline-label.pipe';
import { DateToStringPipe } from './pipes/date-to-string.pipe';
import { FilterIdentifierTypesPipe } from "./pipes/filter-identifier-types.pipe";
import { ConnectivityScheduleEventTypePipe } from "./pipes/connectivity-schedule-event-type.pipe";
import {TranslationFieldPipe} from "./pipes/marketing-names.pipe";
import {LanguagePipe} from "./pipes/language.pipe";

@NgModule({
  declarations: [
    BooleanToYesNoPipe,
    ValueOrDashPipe,
    RoundAndFormatPipe,
    CustomErrorsTextPipe,
    MultilineLabelPipe,
    DateToStringPipe,
    FilterIdentifierTypesPipe,
    ConnectivityScheduleEventTypePipe,
    TranslationFieldPipe,
    LanguagePipe
  ],
  imports: [CommonModule],
  exports: [
    BooleanToYesNoPipe,
    ValueOrDashPipe,
    RoundAndFormatPipe,
    CustomErrorsTextPipe,
    MultilineLabelPipe,
    DateToStringPipe,
    FilterIdentifierTypesPipe,
    ConnectivityScheduleEventTypePipe,
    TranslationFieldPipe,
    LanguagePipe
  ],
})
export class SharedModule {}
