import { Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { ApiService } from './api.service';
import { CLIENTS_MOCKUP } from '../../admin/services/clients-mockup.const';
import { Client } from '../../admin/model/client';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Role } from '../../admin/model/role.interface';
import { SortOrder } from '../../admin/components/utils/header/header.component';
import { UpdateUserRequest } from '../../admin/model/auth-requests';
import { User } from '../model/user';
import { environment } from 'src/environments/environment';

const HOSTNAME_O3 = environment.api.o3;

export interface UserResponse {
  total: number;
  users: Array<User>;
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private apiService: ApiService) {}

  delete(username: string): Observable<void> {
    const request = { username };
    return this.apiService.post(`user/disable`, request, HOSTNAME_O3);
  }

  trueDelete(username: string): Observable<void> {
    const request = { username };
    return this.apiService.post(`user/delete`, request, HOSTNAME_O3);
  }

  adminResetPassword(username: string) {
    const request = { username };
    return this.apiService.post(
      `user/admin-reset-password`,
      request,
      HOSTNAME_O3
    );
  }

  updateUser(
    account: string,
    email: string,
    username: string,
    groups: string[]
  ): Observable<User> {
    const request: UpdateUserRequest = {
      groups,
    };
    return this.apiService.post(
      'auth/admin/' + account + '/user/' + username,
      request,
      HOSTNAME_O3
    );
  }

  addUser(email: string, username: string): Observable<any> {
    const request = {
      username,
      email,
    };
    return this.apiService.post('users', request, HOSTNAME_O3);
  }

  users(
    filter = '',
    limit = 50,
    page = 0,
    order: SortOrder = { column: 'username', order: 'desc' }
  ): Observable<UserResponse> {
    // TODO: pass the query params after api is ready and remove pipe
    return this.apiService.get<User[]>(`users/list`, HOSTNAME_O3).pipe(
      map((users) => {
        return {
          total: users.length,
          users,
        } as UserResponse;
      })
    );
  }

  user(username: string): Observable<User> {
    const params = new HttpParams().set('username', username);
    return this.apiService.get(`user?${params.toString()}`, HOSTNAME_O3);
  }

  addUserRole(username: string, roleId: string): Observable<Role[]> {
    const request = {
      username,
      group: roleId,
    };
    return this.apiService.post('user/add-role', request, HOSTNAME_O3);
  }

  addUserClient(username: string, clientId: string): Observable<Client[]> {
    const request = {
      username,
      tenantId: clientId,
    };
    // return of(CLIENTS_MOCKUP.clients);
    return this.apiService.post('user/assign-tenant', request, HOSTNAME_O3);
  }

  removeUserRole(username: string, roleId: string): Observable<Role[]> {
    const request = {
      username,
      group: roleId,
    };
    return this.apiService.post('user/remove-role', request, HOSTNAME_O3);
  }

  removeUserClient(username: string, clientId: string): Observable<Client[]> {
    const request = {
      username,
      tenantId: clientId,
    };
    // return of(CLIENTS_MOCKUP.clients.filter((c) => c.id !== clientId));
    return this.apiService.post('user/remove-tenant', request, HOSTNAME_O3);
  }
}
