export const HOSTNAME = window.location.hostname;

export const environment = {
  production: true,
  name: 'Prod',
  tenantId: 'DB',
  hostname: HOSTNAME,
  api: {
    hostname: HOSTNAME,
    docgen: `https://dg.${HOSTNAME}/docgen/`,
    docComparison: `https://pd.${HOSTNAME}/diff/`,
    o3: `https://um.${HOSTNAME}/users/`,
    productCapture: `https://lm.${HOSTNAME}/lifecycle/`,
    schedule: `https://sc.${HOSTNAME}/schedule/`,
    workflow: `https://wf.${HOSTNAME}/workflow/`,
    staticData: `https://sd.${HOSTNAME}/staticdata/`,
    analyticsService: `https://as.${HOSTNAME}/analytics/`,
    releaseManagement: `https://rmb.${HOSTNAME}/release/`,
    releaseManagementWss: `wss://rmb-wss.${HOSTNAME}/`,
    dataIngestion: `https://dipr.${HOSTNAME}/pipeline-runner/`,
    dataEnrichment: `https://disde.${HOSTNAME}/di-staticdata/`,
    rulesProcessor: `https://rp.${HOSTNAME}/rulesprocessor/`,
    productData: `https://pdata.${HOSTNAME}/product-data/`,
    regression: `https://rg.${HOSTNAME}/regression/`,
    notification: `wss://ws.${HOSTNAME}/`,
    connectivity: `https://api.${HOSTNAME}/connectivity/`,
    ipb: `https://api.${HOSTNAME}/backend/`,
  },
  workflow: {
    api: {
      workflowEngine: `https://api.${HOSTNAME}/embeddedWorkflowEngine`
    },
    settings: {
      username: `admin`,
      password: `test`
    }
  }
};
