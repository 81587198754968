<nav class="navbar navbar-expand-sm navbar-dark bg-dark">
  <button
    (click)="navbarMenuButton()"
    [class.open]="!navbarMenuCollapsed"
    aria-controls="navbarOptions"
    aria-expanded="false"
    aria-label="Toggle navigation"
    class="navbar-toggler border-0 pl-0 pr-3"
    data-target="#navbarOptions"
    data-toggle="collapse"
    id="navBar"
    type="button"
  >
    <span class="line"></span>
    <span class="line"></span>
    <span class="line"></span>
  </button>
  <app-logo [environment]="environment$ | async"></app-logo>
  <div
    [animation]="false"
    [ngbCollapse]="navbarMenuCollapsed"
    class="collapse navbar-collapse"
    id="navbarOptions"
  >
    <ng-container *ngIf="loading$ | async">
      <ul class="navbar-nav mr-auto flex-nowrap">
        <ng-container *ngFor="let navItem of navItems">
          <ng-container 
            *ngTemplateOutlet="navItem.roles ? restrictedRoles : defaultRoute; context: { $implicit: navItem }">
          </ng-container>
        </ng-container>
      </ul>
    </ng-container>
    <ng-template #restrictedRoles let-navItem>
      <li *restrictToRoles="navItem.roles" class="nav-item">
        <a class="nav-link" routerLinkActive="active" [routerLink]="navItem.route">{{
          navItem.label
        }}</a>
      </li>
    </ng-template>
    <ng-template #defaultRoute let-navItem>
      <li class="nav-item" *restrictTo="navItem.restrictTo">
        <a class="nav-link" routerLinkActive="active" [routerLink]="navItem.route">{{
          navItem.label
        }}</a>
      </li>
    </ng-template>
  </div>
  <div
    #toggleButton
    (click)="
      userPanelCollapsed = !userPanelCollapsed; navbarMenuCollapsed = true
    "
    [attr.aria-expanded]="!userPanelCollapsed"
    aria-controls="userPanelCollapsed"
    aria-expanded="false"
    aria-label="Toggle navigation"
    class="userContainer d-flex cursor-pointer"
    data-target="#userPanelCollapsed"
    data-toggle="collapse"
    id="userPanel"
  >
    <div class="userName text-large d-none d-sm-block mr-1">{{ user }}</div>
    <div class="ml-2 userIcon"></div>
  </div>
  <div
    #userMenu
    [animation]="false"
    [ngbCollapse]="userPanelCollapsed"
    class="userPanel"
  >
    <div class="p-3 border-bottom border-faded12">
      <div class="userContainer d-flex">
        <div class="userIcon"></div>
        <div class="userInfo">
          <div class="userName text-large text-truncate text-white pl-2">
            {{ user }}
          </div>
          <div class="userEmail text-small text-truncate pl-2">{{ email }}</div>
        </div>
      </div>
    </div>
    <div
      *restrictTo="'VIEW_ADMIN_SECTION'"
      class="section p-3 border-bottom border-faded12"
    >
      <a
        routerLink="auth-o3/admin"
        class="btn btn-link text-white p-0"
        role="button"
      >
        User Management
      </a>
    </div>
    <div class="section p-3">
      <button (click)="signOut()" class="btn btn-link text-white p-0">
        Sign Out
      </button>
    </div>
  </div>
</nav>
<ng-container *ngIf="environment$ | async as environment">
  <div
    *ngIf="environment?.trim().length"
    class="environment__bar {{ environment.toLowerCase() }}"
  ></div>
</ng-container>
